import React from 'react';

const Loader = () => {
    return (
        <div style={loaderContainer}>
            <div id='loader'></div>
        </div>
    )
}

const loaderContainer = {
    position: 'fixed',
    zIndex: "1000000",
    background: "#fff",
    width: "100%",
    height: "100%"
};

export default Loader;
