import React, { lazy, Suspense } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Loader from "./components/layouts/Loader";
const Home = lazy(() => import("./components/routes/Home"));
const Header = lazy(() => import("./components/layouts/Header"));
const LegalLinks = lazy(() => import("./components/routes/LegalLinks"));
const NotFound = lazy(() => import("./components/routes/NotFound"));
const Bditto = lazy(() => import("./components/routes/Bditto"));
const Brillwork = lazy(() => import("./components/routes/Brillwork"));
const Shnipp = lazy(() => import("./components/routes/Shnipp"));
const Meethome = lazy(() => import("./components/routes/Meethome"));
const Thoughtz = lazy(() => import("./components/routes/Thoughtz"));
// const Cursor = lazy(() => import("./components/layouts/Cursor"))

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        {/* <Cursor /> */}
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/legal/links" component={LegalLinks} />
          <Route exact path="/bditto" component={Bditto} />
          <Route exact path="/brillwork" component={Brillwork} />
          <Route exact path="/shnipp" component={Shnipp} />
          <Route exact path="/meethome" component={Meethome} />
          <Route exact path="/thoughtz" component={Thoughtz} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;
